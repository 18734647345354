<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <div class="ma-2 pa-2 text-h6">1Life Software - New Password</div>
      </v-footer>
      <v-card-text class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <p>
                You have logged in with a temporary password. You must choose a
                new password to continue.
              </p>
              <p>Password must meet the following requirements:</p>
              <ul>
                <li>Contain at least one lower case letter</li>
                <li>Contain at least one capital letter</li>
                <li>Contain at least one number</li>
                <li>Be at least 6 characters in length</li>
                <li>Cannot not contain the # character</li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                filled
                depressed
                hide-details
                background-color="white"
                v-model="password"
                label="New password"
                color="msaBlue"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                filled
                depressed
                hide-details
                background-color="white"
                v-model="password2"
                label="Confirm new password"
                color="msaBlue"
                :style="{ 'border-radius': '5px' }"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="msaBlue white--text"
          :disabled="confirmDisabled"
          @click="vaildate"
        >
          Set New Password
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ChangePasswordOnLoginDialog',
  data() {
    return {
      dialog: false,
      user: null,
      token: null,
      isLoading: false,
      password: '',
      password2: '',
      resultMessage: '',
      showPassword: false,
    };
  },
  computed: {
    confirmDisabled() {
      return this.isLoading;
    },
  },
  methods: {
    show(user, token) {
      //require so that user cannot bypass pwd reset by changing url
      this.$store.commit('invalidateStore');
      this.user = user;
      this.token = token;
      this.dialog = true;
    },
    close() {
      this.user = null;
      this.token = null;
      this.dialog = false;
    },
    vaildate() {
      if (this.password != this.password2) {
        this.$root.showMessage(
          'Invalid Password',
          'Passwords do not match',
          () => false,
          null,
          'OK',
          null,
        );
        return;
      }
      var result = this.$helpers.validatePasswordAll(this.password);
      if (result.length === 0) {
        this.setNewPassword();
        return;
      }
      this.$root.showMessage(
        'Invalid Password',
        result,
        () => false,
        null,
        'OK',
        null,
      );
    },
    setNewPassword() {
      this.isLoading = true;
      this.$store.commit('updateUser', this.user);
      this.$store.commit('updateJwtToken', this.token);
      const params = {
        loaderText: 'Setting new password...',
        password: this.password,
      };
      this.$axios
        .post('change-password-on-login?format=json', params)
        .then(() => {
          this.$emit('passwordChanged', { user: this.user, token: this.token });
          this.dialog = false;
        })
        .catch(() => {
          this.$store.commit('invalidateStore');
          this.isLoading = false;
        });
    },
  },
};
</script>
