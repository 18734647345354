<template>
  <v-container class="pa-0 ma-0" fluid>
    <TermsOfServiceDialog ref="terms" @accepted="onAccepted" />
    <ChangePasswordOnLoginDialog
      ref="newPasswordDialog"
      @passwordChanged="onPasswordChanged"
    />
    <v-row :style="{ height: '33vh', 'min-height': '250px' }" align="center">
      <v-col align="center">
        <v-img src="../assets/1lifelogo.png" max-width="300px"> </v-img>
      </v-col>
    </v-row>
    <v-row align="end" class="msaBlue" justify="center">
      <v-col align="center" :cols="isMobile ? 10 : 4">
        <v-row>
          <v-col> &nbsp; </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              label="Username"
              background-color="white"
              v-model="username"
              maxlength="128"
              color="msaBlue"
              filled
              depressed
              hide-details
              :style="{ 'border-radius': '5px' }"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @keydown.enter="login"
              @click:append="showPassword = !showPassword"
              filled
              depressed
              hide-details
              background-color="white"
              v-model="password"
              label="Password"
              color="msaBlue"
              :style="{ 'border-radius': '5px' }"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="green white--text" @click="login()" block>
              Login
            </v-btn>
            <v-row class="mt-4 white--text">
              <v-col
                ><strong
                  ><v-btn text color="white" @click="gotoForgotPassword"
                    >Forgot password?
                  </v-btn></strong
                ></v-col
              ></v-row
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TermsOfServiceDialog from '../components/TermsOfServiceDialog.vue';
import ChangePasswordOnLoginDialog from '../components/ChangePasswordOnLoginDialog.vue';
import { configureUser, logInUser } from '../global/functions.js';
export default {
  components: { TermsOfServiceDialog, ChangePasswordOnLoginDialog },
  name: 'LoginPage',
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    onPasswordChanged() {
      this.versionCheck();
      logInUser(this);
    },
    onAccepted(data) {
      if (data.user.requirePasswordReset) {
        this.$refs.newPasswordDialog.show(data.user, data.token);
        return;
      }
      this.versionCheck();
      logInUser(this);
    },
    gotoForgotPassword() {
      this.$router.push({ name: 'ForgotPassword' });
    },
    login() {
      const url = 'login?format=json';
      const params = {
        username: this.username,
        password: this.password,
        loaderText: 'Login...',
      };
      this.$axios
        .post(url, params)
        .then((response) => {
          const user = configureUser(this, response.data);
          const token = response.data.token;

          //no additional check for internal admins
          if (user.type == this.$constants.USER_TYPES.INTERNAL_ADMIN) {
            this.$store.commit('updateUser', user);
            this.$store.commit('updateJwtToken', token);
            this.versionCheck();
            logInUser(this);
            return;
          }
          if (user.acceptedTermsOfService == 0) {
            this.$refs.terms.show(user, token);
            return;
          }
          if (user.requirePasswordReset) {
            this.$refs.newPasswordDialog.show(user, token);
            return;
          }
          this.$store.commit('updateUser', user);
          this.$store.commit('updateJwtToken', token);

          this.versionCheck();
          logInUser(this);
        })
        .catch((error) => error);
    },
    versionCheck() {
      const url = 'version-check?format=json';
      this.$axios
        .post(url, { loadText: 'Checking updates...' })
        .then((response) => {
          if (
            process.env.NODE_ENV != 'development' &&
            this.$root.appVersion != response.data
          ) {
            this.$root.showMessage(
              'New version available',
              'A new version of 1Life software is available.<br> Please reload the page to get the latest version.',
              () => this.$router.go(),
              null,
              'Reload Page',
              null,
            );
          }
        });
    },
  },
  mounted() {
    // if logout is set, do not redirect
    if (this.$route.params.logout) {
      return;
    }

    if (this.$store.getters.user != '') {
      if (this.$store.getters.isInternalAdmin) {
        this.$router.push({ name: 'CompanyList' });
      } else {
        this.$router.push({ name: 'UserHome' });
      }
    }
  },
};
</script>
